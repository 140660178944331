<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Новости', to: '/newses'}, {text: news.name}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="8"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="news.name"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <base-material-alert
                  v-if="id !== 'create' && !news.status"
                  color="warning"
                  dark
                  icon="mdi-zip-disk"
                >
                  Неопубликованная новость!
                </base-material-alert>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="news.name"
                  required
                  label="Заголовок *"
                  :error-messages="fieldError('name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="news.newscategory_id"
                  label="Категория"
                  :items="newsCategories"
                  :error-messages="fieldError('newscategory_id')"
                  item-value="id"
                  item-text="name"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <editor-menu-bar
                  v-slot="{ commands, isActive }"
                  :editor="editor"
                >
                  <div>
                    <v-btn
                      text
                      icon
                      @click="commands.undo"
                    >
                      <v-icon>mdi-undo-variant</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      @click="commands.redo"
                    >
                      <v-icon>mdi-redo-variant</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <v-icon>mdi-format-bold</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <v-icon>mdi-format-italic</v-icon>
                    </v-btn>

                    <v-btn
                      text
                      icon
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <v-icon>mdi-format-underline</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <v-icon>mdi-format-list-numbered</v-icon>
                    </v-btn>
                  </div>
                </editor-menu-bar>
                <editor-content
                  class="editor-box"
                  :editor="editor"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="news.url"
                  required
                  label="Новость со ссылкой на"
                  :error-messages="fieldError('url')"
                />
              </v-col>
              <v-col
                v-if="news.catImagesCount > 0"
                cols="12"
                md="12"
              >
                <v-card>
                  <v-container fluid>
                    <v-row>
                      <v-col
                        v-for="item in news.catImagesIds"
                        :key="item"
                        class="d-flex child-flex"
                        cols="3"
                      >
                        <v-card
                          flat
                          tile
                          class="d-flex"
                        >
                          <v-img
                            :src="imageLink(item)"
                            :lazy-src="imageLink(item)"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            :title="`Картинка #${item}`"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                />
                              </v-row>
                            </template>
                          </v-img>
                          <v-btn
                            absolute
                            dark
                            fab
                            small
                            color="error"
                            :title="`Удалить картинку #${item}`"
                            @click="confirmRemoveImage(item)"
                          >
                            <v-icon>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <file-pond
                  ref="pond"
                  label-idle="Перетащите картинки к новости сюда или <span class=&quot;filepond--label-action&quot;>Выберите с компьютера </span><br><i>*Первый приложенный файл будет отображаться рядом с анонсом.<i>"
                  allow-multiple="true"
                  accepted-file-types="image/gif, image/jpeg, image/png"
                  allow-image-resize="true"
                  image-resize-target-width="2000"
                  image-resize-target-height="2000"
                  image-resize-mode="contain"
                  image-resize-upscale="false"
                  :server="serverOptions"
                  :files="catImages"
                  @removefile="onRemoveFile"
                  @processfile="onProcessFile"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    v-if="id === 'create' || news.status == 0"
                    color="success"
                    min-width="100"
                    @click="previewNews()"
                  >
                    Опубликовать
                  </v-btn>

                  <v-btn
                    v-else
                    color="success"
                    min-width="100"
                    @click="saveNews()"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    v-if="id !== 'create' && news.status"
                    color="warning"
                    min-width="100"
                    @click="archive()"
                  >
                    Переместить в архив
                  </v-btn>

                  <v-btn
                    v-if="id !== 'create' && news.status"
                    color="teal"
                    min-width="100"
                    :href="singleNewsLink(news)"
                    target="_blank"
                    title="Открыть новость на сайте в новом окне"
                  >
                    <v-icon>mdi-open-in-new</v-icon> Открыть новость на сайте
                  </v-btn>

                  <v-btn
                    v-if="id !== 'create' && !news.status"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить новость?
        </v-card-title>

        <v-card-text>
          Новость будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removeNews(news.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmImageDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить картинку?
        </v-card-title>

        <v-card-text>
          Картинка будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmImageDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removeImage()"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
  .v-data-table tr, .cursor-pointer {
    cursor: pointer;
  }

  .client-title-link:hover {
    text-decoration: underline;
  }

  .abo-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }

  .filepond--item {
    width: calc(25% - .5em);
    height: 100px;
  }

</style>

<script>
  import newsApi from '@/services/NewsApi'
  import moment from 'moment'
  // Import FilePond
  import vueFilePond from 'vue-filepond'
  import FilePondPluginFileValidateType
    from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js'
  import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm.js'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js'
  import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm.js'
  import 'filepond/dist/filepond.min.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
  import JwtService from '../services/jwt.service'
  import catImagesApi from '../services/CatImagesApi'
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import { Heading,
           History,
           Bold,
           Underline,
           Italic,
           OrderedList,
           BulletList,
           ListItem,

           Placeholder } from 'tiptap-extensions'

  const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImageResize, FilePondPluginImagePreview, FilePondPluginImageTransform)

  export default {
    filters: {
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },
    components: {
      FilePond,
      EditorContent,
      EditorMenuBar,
    },
    props: ['id'],
    data () {
      return {
        editor: new Editor({
          extensions: [
            new History(),
            new Bold(),
            new Italic(),
            new ListItem(),
            new BulletList(),
            new OrderedList(),
            new Underline(),
            new Placeholder({
              emptyEditorClass: 'is-editor-empty',
              emptyNodeClass: 'is-empty',
              emptyNodeText: 'Текст новости …',
              showOnlyWhenEditable: true,
              showOnlyCurrent: true,
            }),
          ],
          onUpdate: ({ getHTML }) => {
            this.news.text = getHTML()
          },
        }),

        newsCategories: [],
        news: {
          id: null,
          name: null,
          catImages: [],
          catImagesIds: [],
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },

        confirmDialog: false,

        serverOptions: {
          url: process.env.VUE_APP_API_URL,
          headers: {
            'Authorization': 'Bearer ' + JwtService.getToken(),
            // 'x-access-token': JwtService.getToken(),
          },
          process: 'upload/upload',
          revert: 'upload/revert',
          restore: 'upload/restore',
          load: 'upload/load',
          fetch: 'upload/fetch',
        },
        catImages: [],
        confirmImageDialog: false,
        confirmImageId: null,
        serverIdMap: {},
      }
    },

    computed: {
      uploadUrl () {
        return process.env.VUE_APP_API_URL + 'upload'
      },
    },

    mounted () {
      document.title = 'Новость #' + this.id + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.fetchData()
    },

    beforeDestroy () {
      this.editor.destroy()
    },

    methods: {
      fetchData () {
        newsApi.fetchNewsCategories()
          .then(response => {
            this.newsCategories = response
            if (this.id === 'create') {
              this.loading = false
              this.news = {
                id: null,
                name: null,
                catImages: [],
                catImagesIds: [],
                status: 0,
                newscategory_id: null,
              }
              if (this.newsCategories && this.newsCategories.length > 0) {
                this.news.newscategory_id = this.newsCategories[0].id
              }
            } else {
              newsApi
                .fetchNews(this.id)
                .then(response => {
                  this.news = response
                  this.editor.setContent(this.news.text)
                  this.loading = false
                  document.title = this.news.name + ' | ' + process.env.VUE_APP_NAME
                }).catch(error => {
                  this.error = this.pretty(error.response.data)
                })
            }
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      back () {
        this.$router.push('/')
      },

      archive () {
        this.news.status = 0
        this.saveNews()
      },
      unarchive () {
        this.news.status = 1
        this.saveNews()
      },
      singleNewsLink (news) {
        return process.env.VUE_APP_OLD_URL + 'news/link/' + news.id + '?_rid=' + moment().unix()
      },
      previewNews () {
        if (this.news.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          newsApi
            .updateNews(this.news)
            .then(response => {
              this.news = response
              window.location.href = process.env.VUE_APP_OLD_URL + 'news/link/' + this.news.id + '?_rid=' + moment().unix()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          newsApi
            .createNews(this.news)
            .then(response => {
              this.news = response
              window.location.href = process.env.VUE_APP_OLD_URL + 'news/link/' + this.news.id + '?_rid=' + moment().unix()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },
      saveNews () {
        if (this.news.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          newsApi
            .updateNews(this.news)
            .then(response => {
              this.news = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          newsApi
            .createNews(this.news)
            .then(response => {
              this.news = response
              this.$router.push('/news/' + this.news.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },
      removeNews () {
        this.confirmDialog = false
        this.loading = true
        newsApi
          .deleteNews(this.news.id)
          .then(response => {
            // this.news = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },

      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },
      range: function (start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
      },

      getCatImage (index) {
        return this.news.catImages[index]
      },

      imageLink (id) {
        return process.env.VUE_APP_OLD_URL + 'i/300/300/' + id
      },

      confirmRemoveImage (id) {
        this.confirmImageDialog = true
        this.confirmImageId = id
      },

      removeImage () {
        this.confirmImageDialog = false
        catImagesApi
          .deleteImage(this.confirmImageId)
          .then(response => {
            const index = this.news.catImagesIds.indexOf(this.confirmImageId, 0)
            if (index > -1) {
              this.news.catImagesIds.splice(index, 1)
            }
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },

      onRemoveFile: function (error, file) {
        let serverId = this.serverIdMap[file.id]
        if (!error && serverId) {
          const index = this.news.catImagesIds.indexOf(serverId, 0)
          if (index > -1) {
            this.news.catImagesIds.splice(index, 1)
          }
        } else {
          console.log(error)
        }
      },

      onProcessFile: function (error, file) {
        this.serverIdMap[file.id] = file.serverId
        if (!error) {
          const files = this.$refs.pond.getFiles()
          var self = this
          files.forEach(function (file) {
            if (file.serverId && self.news.catImagesIds.indexOf(file.serverId, 0) === -1) {
              self.news.catImagesIds.push(file.serverId)
            }
          })
        } else {
          console.log(error)
        }
      },
    },
  }
</script>

<style >
  .editor-box> * {
    border-bottom-color: #d6d6d6;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .is-active{
    background-color: #e3e3e3;
    border-style: solid;
    border-width: 1px;
  }
  *:focus {
     outline: none;
  }
</style>
