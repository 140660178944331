var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "client-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Новости", to: "/newses" },
                        { text: _vm.news.name }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10", lg: "8" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "success",
                        icon: "mdi-badge-account",
                        title: _vm.news.name
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _vm.id !== "create" && !_vm.news.status
                                    ? _c(
                                        "base-material-alert",
                                        {
                                          attrs: {
                                            color: "warning",
                                            dark: "",
                                            icon: "mdi-zip-disk"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Неопубликованная новость!\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Заголовок *",
                                      "error-messages": _vm.fieldError("name")
                                    },
                                    model: {
                                      value: _vm.news.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.news, "name", $$v)
                                      },
                                      expression: "news.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Категория",
                                      items: _vm.newsCategories,
                                      "error-messages": _vm.fieldError(
                                        "newscategory_id"
                                      ),
                                      "item-value": "id",
                                      "item-text": "name"
                                    },
                                    model: {
                                      value: _vm.news.newscategory_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.news,
                                          "newscategory_id",
                                          $$v
                                        )
                                      },
                                      expression: "news.newscategory_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("editor-menu-bar", {
                                    attrs: { editor: _vm.editor },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var commands = ref.commands
                                            var isActive = ref.isActive
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: commands.undo
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-undo-variant"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: commands.redo
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-redo-variant"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      class: {
                                                        "is-active": isActive.bold()
                                                      },
                                                      attrs: {
                                                        text: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: commands.bold
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-format-bold"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      class: {
                                                        "is-active": isActive.italic()
                                                      },
                                                      attrs: {
                                                        text: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: commands.italic
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-format-italic"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      class: {
                                                        "is-active": isActive.underline()
                                                      },
                                                      attrs: {
                                                        text: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click:
                                                          commands.underline
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-format-underline"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      class: {
                                                        "is-active": isActive.bullet_list()
                                                      },
                                                      attrs: {
                                                        text: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click:
                                                          commands.bullet_list
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-format-list-bulleted"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      class: {
                                                        "is-active": isActive.ordered_list()
                                                      },
                                                      attrs: {
                                                        text: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click:
                                                          commands.ordered_list
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-format-list-numbered"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3168466311
                                    )
                                  }),
                                  _c("editor-content", {
                                    staticClass: "editor-box",
                                    attrs: { editor: _vm.editor }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Новость со ссылкой на",
                                      "error-messages": _vm.fieldError("url")
                                    },
                                    model: {
                                      value: _vm.news.url,
                                      callback: function($$v) {
                                        _vm.$set(_vm.news, "url", $$v)
                                      },
                                      expression: "news.url"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.news.catImagesCount > 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-container",
                                            { attrs: { fluid: "" } },
                                            [
                                              _c(
                                                "v-row",
                                                _vm._l(
                                                  _vm.news.catImagesIds,
                                                  function(item) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: item,
                                                        staticClass:
                                                          "d-flex child-flex",
                                                        attrs: { cols: "3" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                            attrs: {
                                                              flat: "",
                                                              tile: ""
                                                            }
                                                          },
                                                          [
                                                            _c("v-img", {
                                                              staticClass:
                                                                "grey lighten-2",
                                                              attrs: {
                                                                src: _vm.imageLink(
                                                                  item
                                                                ),
                                                                "lazy-src": _vm.imageLink(
                                                                  item
                                                                ),
                                                                "aspect-ratio":
                                                                  "1",
                                                                title:
                                                                  "Картинка #" +
                                                                  item
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "placeholder",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            staticClass:
                                                                              "fill-height ma-0",
                                                                            attrs: {
                                                                              align:
                                                                                "center",
                                                                              justify:
                                                                                "center"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-progress-circular",
                                                                              {
                                                                                attrs: {
                                                                                  indeterminate:
                                                                                    "",
                                                                                  color:
                                                                                    "grey lighten-5"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  absolute: "",
                                                                  dark: "",
                                                                  fab: "",
                                                                  small: "",
                                                                  color:
                                                                    "error",
                                                                  title:
                                                                    "Удалить картинку #" +
                                                                    item
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.confirmRemoveImage(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "\n                            mdi-close\n                          "
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("file-pond", {
                                    ref: "pond",
                                    attrs: {
                                      "label-idle":
                                        'Перетащите картинки к новости сюда или <span class="filepond--label-action">Выберите с компьютера </span><br><i>*Первый приложенный файл будет отображаться рядом с анонсом.<i>',
                                      "allow-multiple": "true",
                                      "accepted-file-types":
                                        "image/gif, image/jpeg, image/png",
                                      "allow-image-resize": "true",
                                      "image-resize-target-width": "2000",
                                      "image-resize-target-height": "2000",
                                      "image-resize-mode": "contain",
                                      "image-resize-upscale": "false",
                                      server: _vm.serverOptions,
                                      files: _vm.catImages
                                    },
                                    on: {
                                      removefile: _vm.onRemoveFile,
                                      processfile: _vm.onProcessFile
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pl-0" },
                                    [
                                      _vm.id === "create" ||
                                      _vm.news.status == 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "success",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.previewNews()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Опубликовать\n                "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "success",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveNews()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Сохранить\n                "
                                              )
                                            ]
                                          ),
                                      _vm.id !== "create" && _vm.news.status
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Переместить в архив\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.id !== "create" && _vm.news.status
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "teal",
                                                "min-width": "100",
                                                href: _vm.singleNewsLink(
                                                  _vm.news
                                                ),
                                                target: "_blank",
                                                title:
                                                  "Открыть новость на сайте в новом окне"
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-open-in-new")
                                              ]),
                                              _vm._v(
                                                " Открыть новость на сайте\n                "
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.id !== "create" && !_vm.news.status
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-1",
                                              attrs: {
                                                color: "error",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.confirmDialog = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Удалить\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить новость?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Новость будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeNews(_vm.news.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmImageDialog,
            callback: function($$v) {
              _vm.confirmImageDialog = $$v
            },
            expression: "confirmImageDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить картинку?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Картинка будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmImageDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeImage()
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }