import axios from 'axios'

export default {

  deleteImage (imageId) {
    return axios.delete('upload/revert', { data: imageId }).then(response => {
      return response.data
    })
  },

}
